<script setup>
import { defineEmits, ref } from "vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Auth from "@/components/General/Auth/auth";
import { Error } from "@/core/plugins/swal";

const emit = defineEmits(["login"]);
const formHelper = ref(null);
const linkSent = ref(false);
const isLoading = ref(false);
const formData = ref({
  email: ""
});
const form = ref([
  {
    type: "text",
    name: "email",
    label: "passwordResetDescription",
    placeholder: "mail",
    validations: {
      required: true,
      email: true
    }
  }
]);
const config = ref({
  title: "",
  snippetPrefix: "login",
  labelStacked: true
});
function sendPasswordResetLink() {
  if (formHelper.value.validate() !== true) {
    return;
  }
  isLoading.value = true;
  Auth.sendPasswordResetLink(formData.value)
    .then(() => {
      linkSent.value = true;
      isLoading.value = false;
    })
    .catch(error => {
      Error(error);
      isLoading.value = false;
    });
}
</script>

<template>
  <div class="password-forgotten">
    <div v-if="!linkSent">
      <div
        class="text-dark font-weight-bolder font-size-h4 font-size-h1-lg mb-3"
      >
        {{ $t("login.passwordForgotten") }}
      </div>
      <FormHelper
        ref="formHelper"
        v-model="formData"
        :form="form"
        :config="config"
      />
      <div class="mt-5">
        <button
          class="btn btn-primary px-15 py-4"
          :class="{ 'spinner spinner-right': isLoading }"
          :disabled="isLoading"
          @click="sendPasswordResetLink"
        >
          {{ $t("login.passwordResetSubmit") }}
        </button>
        <button
          class="btn btn-secondary ml-3 px-15 py-4"
          @click="emit('login')"
        >
          {{ $t("general.cancel") }}
        </button>
      </div>
    </div>
    <div v-else>
      <div
        class="text-dark font-weight-bolder font-size-h4 font-size-h1-lg mb-3"
      >
        {{ $t("login.passwordResetMailSentTitle") }}
      </div>
      <p class="font-size-lg">{{ $t("login.passwordResetMailSent") }}</p>
      <button
        type="button"
        class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
        @click="emit('login')"
      >
        {{ $t("login.backToLogin") }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.password-forgotten {
  width: 100%;
  max-width: 500px;
}
button {
  font-size: 16px;
  font-weight: bold;
}
</style>
