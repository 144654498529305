<script setup>
import {
  defineEmits,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
  ref,
  defineProps
} from "vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { Toast } from "@/core/plugins/swal";
import Auth from "@/components/General/Auth/auth";
import Swal from "sweetalert2";
import { useRouter } from "vue-router/composables";
import TwoFactorAuthentication from "@/components/General/Auth/Components/TwoFactorAuthentication.vue";

const router = useRouter();
const i18n = getCurrentInstance().proxy.$i18n;

const emit = defineEmits(["password-reset", "login"]);
const props = defineProps({
  targetRoute: {
    type: String,
    default: null
  }
});

const twoFaModal = ref(null);
const formHelper = ref(null);
const loginData = ref({
  email: "",
  password: ""
});
const form = ref([
  {
    type: "text",
    name: "email",
    label: "mail",
    validations: {
      required: true,
      email: true
    },
    disableReturnType: true
  },
  {
    type: "password",
    name: "password",
    label: "password",
    validations: {
      required: true,
      minLength: 8
    }
  }
]);
const formConfig = ref({
  snippetPrefix: "login",
  labelStacked: true
});
const buttonLoading = ref(false);

onMounted(() => {
  document.addEventListener("keyup", onEnter);
});
onBeforeUnmount(() => {
  document.removeEventListener("keyup", onEnter);
});

function onEnter(e) {
  if (
    e.key !== "Enter" ||
    !e.target.classList.contains("form-control") ||
    twoFaModal.value.isOpen
  ) {
    return;
  }
  login();
}

async function login() {
  // Validate form
  const valid = formHelper.value.validate();
  // If form is invalid, abort login attempt
  if (valid !== true) {
    Toast.fire({
      icon: "warning",
      title: i18n.t("login.formInvalid")
    });
    return;
  }
  buttonLoading.value = true;
  // Send login request
  let authResponse = await Auth.login(loginData.value);
  buttonLoading.value = false;

  if (authResponse.success) {
    emit("login");

    const route = props.targetRoute ?? "projectWorkflows";

    // If login attempt was successful,forward user to app
    await router.push({ name: route });
    return false;
  } else {
    // Else check failure reason
    onLoginFailure(authResponse);
  }
}

function onLoginFailure(authResponse) {
  let responseMessage = "";
  if (typeof authResponse.data.seconds !== "undefined") {
    tooManyLoginAttempts(authResponse);
    responseMessage = i18n.t("login.tooManyLoginAttempts");
  } else if (typeof authResponse.data.twoFaRequired !== "undefined") {
    twoFaLogin();
  } else {
    responseMessage = checkFailureResponses(authResponse);
  }
  if (!responseMessage) {
    return;
  }
  Swal.fire({
    title: i18n.t("general.caution"),
    text: responseMessage,
    icon: "error"
  });
}

function tooManyLoginAttempts(authResponse) {
  self.waitSeconds = authResponse.data.seconds;
  self.waitSecondsMax = authResponse.data.seconds;
  self.tooManyLoginAttempts = true;

  self.interval = setInterval(function () {
    self.waitSeconds -= 1;
    if (self.waitSeconds <= 0) {
      self.tooManyLoginAttempts = false;
      clearInterval(self.interval);
    }
  }, 1000);
}

function twoFaLogin() {
  twoFaModal.value.show();
}

function checkFailureResponses(authResponse) {
  let responseMessage = i18n.t("login.loginDetailsWrong");
  if (typeof authResponse.data.userActiveStatus !== "undefined") {
    responseMessage = i18n.t("login.accountDeactivated");
  }
  if (typeof authResponse.data.locked !== "undefined") {
    responseMessage = i18n.t("login.accountLocked");
  }
  if (typeof authResponse.data.untrustedIP !== "undefined") {
    responseMessage = i18n.t("login.unstrustedIP", {
      ip: authResponse.data.untrustedIP
    });
  }
  return responseMessage;
}
</script>

<template>
  <div
    class="login-form h-100 d-flex justify-content-center align-items-center position-relative"
  >
    <div>
      <!------------ START: Welcome ------------>
      <div class="text-center mb-6">
        <span class="font-weight-bolder mb-7 font-size-h4 font-size-h1-lg">
          {{ $t("login.welcomeAt") }}
        </span>
        <img
          class="img-fluid xentral-logo"
          src="../../../../assets/img/logo.svg"
          :alt="$t('login.logoXentral')"
        />
      </div>
      <!------------ END: Welcome ------------>
      <!------------ START: FormHelper ------------>
      <FormHelper
        ref="formHelper"
        v-model="loginData"
        :form="form"
        :config="formConfig"
      />
      <!------------ END: FormHelper ------------>
      <!------------ START: Actions ------------>
      <div class="d-flex align-items-center justify-content-between mt-5">
        <button
          class="btn btn-primary px-15 py-4"
          :class="{ 'spinner spinner-right': buttonLoading }"
          :disabled="buttonLoading"
          @click="login"
        >
          {{ $t("login.login") }}
        </button>
        <button
          class="btn btn-clear text-primary"
          @click="emit('password-reset')"
        >
          {{ $t("login.passwordForgotten") }}
        </button>
      </div>
      <!------------ END: Actions ------------>
    </div>
    <!------------ START: Imprint ------------>
    <div
      class="position-absolute bottom-0 w-100 d-flex justify-content-center align-items-center mb-5"
    >
      <a href="#" class="text-primary font-weight-bolder font-size-h5 mx-5">
        {{ $t("login.imprint") }}
      </a>
      <a href="#" class="text-primary font-weight-bolder font-size-h5 mx-5">
        {{ $t("login.privacyPolicy") }}
      </a>
    </div>
    <!------------ END: Imprint ------------>
    <!------------ START: 2FA Modal ------------>
    <TwoFactorAuthentication
      ref="twoFaModal"
      :login-data="loginData"
      @login="login"
    />
    <!------------ END: 2FA Modal ------------>
  </div>
</template>

<style lang="scss" scoped>
.login-form {
  font-size: 16px;
  font-weight: bold;
  max-width: 500px;
  width: 100%;

  .xentral-logo {
    max-width: 70%;
  }

  button {
    font-size: 16px;
    font-weight: bold;
  }

  :deep(.form-control) {
    &:not(.is-valid):not(.is-invalid) {
      padding-right: calc(1.5em + 1.3rem);
    }
  }
}
</style>
