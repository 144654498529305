<script setup>
import { getCurrentInstance, ref } from "vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Auth from "@/components/General/Auth/auth";
import { Error, Toast } from "@/core/plugins/swal";
import { useRoute, useRouter } from "vue-router/composables";

const route = useRoute();
const router = useRouter();
const i18n = getCurrentInstance().proxy.$i18n;

const formHelper = ref(null);
const isLoading = ref(false);
const formData = ref({
  password: "",
  password_confirmation: "",
  email: route.query.email,
  token: route.query.token
});
const form = ref([
  {
    type: "password",
    name: "password",
    label: "password",
    placeholder: "password",
    validations: {
      required: true,
      minLength: 8
    }
  },
  {
    type: "password",
    name: "password_confirmation",
    label: "passwordRepeat",
    placeholder: "passwordRepeat",
    validations: {
      required: true,
      sameAs: () => formData.value.password
    }
  }
]);
const config = ref({
  title: "",
  snippetPrefix: "login",
  labelStacked: true
});
function resetPassword() {
  if (formHelper.value.validate() !== true) {
    return;
  }
  isLoading.value = true;
  Auth.resetPassword(formData.value)
    .then(() => {
      Toast.fire({
        icon: "success",
        title: i18n.t("login.passwordResetSuccess")
      });
      router.push({ name: "login" });
    })
    .catch(error => {
      Error(error);
      isLoading.value = false;
    });
}
</script>

<template>
  <div class="password-reset">
    <div class="text-dark font-weight-bolder font-size-h4 font-size-h1-lg mb-3">
      {{ $t("login.passwordResetFor", { email: route.query.email }) }}
    </div>
    <FormHelper
      ref="formHelper"
      v-model="formData"
      :form="form"
      :config="config"
    />
    <div class="mt-5">
      <button
        class="btn btn-primary px-15 py-4"
        :class="{ 'spinner spinner-right': isLoading }"
        :disabled="isLoading"
        @click="resetPassword"
      >
        {{ $t("login.passwordChange") }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.password-reset {
  width: 100%;
  max-width: 500px;
}
button {
  font-size: 16px;
  font-weight: bold;
}
</style>
