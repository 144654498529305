<script setup>
import {
  ref,
  defineEmits,
  defineExpose,
  defineProps,
  getCurrentInstance,
  onMounted,
  onBeforeUnmount
} from "vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Auth from "@/components/General/Auth/auth";
import { useRouter } from "vue-router/composables";

const i18n = getCurrentInstance().proxy.$i18n;
const router = useRouter();

const props = defineProps({
  loginData: {
    type: Object,
    default: () => ({})
  }
});
defineExpose({
  show,
  hide,
  isOpen: showModal
});
const emit = defineEmits(["login"]);

onMounted(() => {
  document.addEventListener("keyup", onEnter);
});
onBeforeUnmount(() => {
  document.removeEventListener("keyup", onEnter);
});

const isLoading = ref(false);
const twoFaFailed = ref(false);
const showModal = ref(false);
const formData = ref({
  twoFaCode: "",
  remember: false
});
const form = ref([
  {
    type: "alert",
    label: "twoFaRequired"
  },
  {
    type: "text",
    name: "twoFaCode",
    label: "twoFaCode",
    placeholder: "enterCode",
    validations: {
      required: true
    }
  },
  {
    type: "checkbox",
    name: "remember",
    label: "saveDeviceAsTrusted"
  }
]);
const formFailed = ref([
  {
    type: "alert",
    label: "twoFaCodeWrong",
    color: "danger"
  }
]);
const formConfig = {
  title: i18n.t("login.twoFaRequiredTitle"),
  snippetPrefix: "login",
  labelStacked: true
};
const formConfigFailed = {
  title: i18n.t("login.twoFaCodeWrongTitle"),
  snippetPrefix: "login",
  labelStacked: true
};
function show() {
  showModal.value = true;
}
function hide() {
  showModal.value = false;
}
async function sendCode() {
  const data = Object.assign({}, props.loginData, formData.value);
  isLoading.value = true;
  // Login again with 2FA code
  let twoFaResponse = await Auth.twoFaCheck(data);
  if (twoFaResponse.success) {
    // If login was successful, forward user to app
    await router.push({ name: "projectWorkflows" });
  } else {
    twoFaFailed.value = true;
  }
}
function onEnter(e) {
  if (
    e.key !== "Enter" ||
    !e.target.classList.contains("form-control") ||
    !showModal.value
  ) {
    return;
  }
  sendCode();
}
function sendAnotherCode() {
  hide();
  isLoading.value = false;
  twoFaFailed.value = false;
  emit("login");
}
</script>

<template>
  <b-modal
    v-model="showModal"
    :hide-header="true"
    :hide-footer="true"
    :centered="true"
  >
    <div v-if="!twoFaFailed">
      <FormHelper v-model="formData" :form="form" :config="formConfig" />
      <div class="mt-5 text-right">
        <button class="btn btn-secondary mr-1" @click="hide">
          {{ $t("general.cancel") }}
        </button>
        <button
          class="btn btn-primary"
          :class="{ 'spinner spinner-right': isLoading }"
          :disabled="isLoading"
          @click="sendCode"
        >
          {{ $t("login.twoFaSend") }}
        </button>
      </div>
    </div>
    <div v-else>
      <FormHelper
        v-model="formData"
        :form="formFailed"
        :config="formConfigFailed"
      />
      <div class="mt-5 text-right">
        <button class="btn btn-secondary mr-1" @click="hide">
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary" @click="sendAnotherCode">
          {{ $t("login.sendMeAnotherCode") }}
        </button>
      </div>
    </div>
  </b-modal>
</template>
